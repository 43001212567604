import React from 'react';
import PropTypes from 'prop-types';

export default class Layout extends React.Component {
  static propTypes = {
    /** Main content of the page */
    children: PropTypes.node.isRequired,
    /** Custom classes to be passed to the <main> element */
    className: PropTypes.string,
    /** Custom css styles to be passed to the <main> element */
    style: PropTypes.object,
    /** Header component to include in the layout */
    header: PropTypes.node,
    /** Footer component to include in the layout */
    footer: PropTypes.node,
    /** Custom classes to be passed to the <div[id=page]> element */
    pageClassName: PropTypes.string,
    /** Custom css styles to be passed to the <div[id=page]> element */
    pageStyle: PropTypes.object,
  };

  static defaultProps = {
    className: '',
    style: {},
    header: null,
    footer: null,
    pageClassName: '',
    pageStyle: {},
  };

  render() {
    return (
      <div id='page' className={this.props.pageClassName} style={this.props.pageStyle}>
        {this.props.header}
        <main className={`${this.props.className}`} style={this.props.style}>
          {this.props.children}
        </main>
        {this.props.footer}
      </div>
    );
  }
}

export class Header extends React.Component {
  static propTypes = {
    /** Content to be displayed in the section */
    children: PropTypes.node.isRequired,
    /** Custom classes to be passed to the <section> element */
    className: PropTypes.string,
    /** Custom css styles to be passed to the <section> element */
    style: PropTypes.object,
    /** Flag to disable default content wrapping */
    fullWidth: PropTypes.bool,
    /** Custom classes to be passed to the <wrapper> element */
    wrapperClassName: PropTypes.string,
    /** Custom css styles to be passed to the <wrapper> element */
    wrapperStyle: PropTypes.object
  };

  static defaultProps = {
    className: '',
    style: {},
    fullWidth: false,
    wrapperClassName: '',
    wrapperStyle: {}
  };

  render(){
    return (
      <header className={this.props.className} style={this.props.style}>
        {!this.props.fullWidth ? <div className={`wrapper ${this.props.wrapperClassName}`.trim()} style={this.props.wrapperStyle}>
          {this.props.children}
        </div> : this.props.children}
      </header>
    )
  }
};

export class Section extends React.Component {
  static propTypes = {
    /** section id/name */
    id: PropTypes.string,
    /** Content to be displayed in the section */
    children: PropTypes.node.isRequired,
    /** Render direct children as columns */
    columns: PropTypes.bool,
    /** Weight to distrubute columns */
    columnWeights: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
    ]),
    /** Custom classes to be passed to the <section> element */
    className: PropTypes.string,
    /** Custom css styles to be passed to the <section> element */
    style: PropTypes.object,
    /** Flag to disable default content wrapping */
    fullWidth: PropTypes.bool,
    /** Custom classes to be passed to the <wrapper> element */
    wrapperClassName: PropTypes.string,
    /** Custom css styles to be passed to the <wrapper> element */
    wrapperStyle: PropTypes.object,
    /** Shortcut to add background image */
    bgImage: PropTypes.string,
  };

  static defaultProps = {
    id: '',
    className: '',
    style: {},
    fullWidth: false,
    wrapperClassName: '',
    wrapperStyle: {},
    bgImage: '',
    columns: false,
    columnWeights: null,
  };

  render() {
    let style = this.props.style;
    if(this.props.bgImage) style = Object.assign({}, {
      backgroundImage: `url('${this.props.bgImage}')`
    }, style);

    let children = this.props.children
    if(this.props.columns){
      const {weights, space, total} = this.extractWeights();
      children = React.Children.map(children, (child, i) => {
          const width = (space/total) * weights[i];
          return React.cloneElement(child, { style: { width: `${width}%`, } });
      });
    }

    return (
      <section id={this.props.id} className={this.props.className} style={style}>
        {!this.props.fullWidth ? <div className={`wrapper ${this.props.columns ? 'columns' : ''} ${this.props.wrapperClassName}`.trim()} style={this.props.wrapperStyle}>
          {children}
        </div> : children}
      </section>
    );
  }

  extractWeights = () => {
    const totalChildren = React.Children.count(this.props.children);
    let weights = Array(totalChildren).fill(1)
    if(this.props.columnWeights){
      let temp = this.props.columnWeights;
      if(typeof columnWeights === 'string') weights = temp.split('-');
      if(temp.length !== totalChildren) console.warn('Children count and columns weights do not match, columns are equally weighted.');
      else weights = temp;
    }

    return {
      weights,
      space: 100 - (totalChildren*2),
      total: weights.reduce((total, c) => total+c, 0)
    };
  }
}

export class Footer extends React.Component {
  static propTypes = {
    /** Content to be displayed in the section */
    children: PropTypes.node.isRequired,
    /** Custom classes to be passed to the <section> element */
    className: PropTypes.string,
    /** Custom css styles to be passed to the <section> element */
    style: PropTypes.object,
    /** Flag to disable default content wrapping */
    fullWidth: PropTypes.bool,
    /** Custom classes to be passed to the <wrapper> element */
    wrapperClassName: PropTypes.string,
    /** Custom css styles to be passed to the <wrapper> element */
    wrapperStyle: PropTypes.object
  };

  static defaultProps = {
    className: '',
    style: {},
    fullWidth: false,
    wrapperClassName: '',
    wrapperStyle: {}
  };

  render(){
    return (
      <footer className={this.props.className} style={this.props.style}>
        {!this.props.fullWidth ? <div className={`wrapper ${this.props.wrapperClassName}`.trim()} style={this.props.wrapperStyle}>
          {this.props.children}
        </div> : this.props.children}
      </footer>
    )
  }
};

Layout.Header = Header;
Layout.Section = Section;
Layout.Footer = Footer;
