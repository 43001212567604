import Buttons, { ButtonStyles, IconButton, CtaButton } from './components/buttons';
import Cards, { CardStyles, ContactCard } from './components/cards';
import Layout, { Header , Section, Footer } from './components/layout';

import ScrollManager, { ScrollEvent } from './utils/scroll.js'

const BasicComponents = {
  Buttons,
  Cards,
  Layout,
}

export default BasicComponents;
export {
  ButtonStyles, IconButton, CtaButton,
  CardStyles, ContactCard,
  Header, Section, Footer,

  ScrollManager, ScrollEvent,
}
