import Uuidv1 from 'uuid/v1';

export default class ScrollManager{
  static _instance = null;

  static getInstance() {
    if (ScrollManager._instance === null) {
      ScrollManager._instance = new ScrollManager();
    }

    return this._instance;
  }

  static generateName() { return `scrollEvent_${Uuidv1()}`; }

  constructor() {
    this.events = [];
    this.scrollPosition = {
      y: window.scrollY,
      x: window.scrollX,
    };
    window.addEventListener('scroll', this.onScroll);
  }

  onScroll = (e) => {
    const params = {
      scrollPosition: {
        y: window.scrollY,
        x: window.scrollX,
      }
    };

    this.events.forEach((event) => event.check(params));
    this.scrollPosition = params.scrollPosition;
  }

  addEventListener(scrollEvent){
    if(this.find(scrollEvent.name) === -1)
      this.events.push(scrollEvent);
    else
      console.warn('Scroll events should have an unique name.')
  }

  removeEventListener(scrollEvent){
    let name = scrollEvent;
    if(typeof scrollEvent === 'object') name = scrollEvent.name;
    const i = this.find(name);
    if(i > -1) this.events.splice(i, 1);
  }

  find(name){
    return this.events.findIndex((event) => event.name === name);
  }
}

export class ScrollEvent{
  constructor(
    func,
    name=ScrollManager.generateName(),
    lowerBound=Number.NEGATIVE_INFINITY,
    upperBound=Number.POSITIVE_INFINITY
  ){
    this.f = func;
    this.name = name;
    this.bounds = {
      lowerBound,
      upperBound,
    }
    this.active = false;
  }

  check(params){
    const isWithinBounds = this.isWithinBounds(params.scrollPosition.y);
    const flag = (
      (isWithinBounds && !this.active) ||
      (!isWithinBounds && this.active)
    );

    if(flag){
      this.active = !this.active;
      this.f({
        name: this.name,
        active: this.active,
      });
    }
  }

  isWithinBounds(scrollPosition){
    return (
      scrollPosition >= this.bounds.lowerBound &&
      scrollPosition <= this.bounds.upperBound
    );
  }
}
