export default class StyleManager {
  constructor(stylesheets = []){
    this.stylesheets = stylesheets;
  }

  add(stylesheet) {
    this.stylesheets.push(stylesheet);
  }

  find(key) {
    return this.stylesheets.reduce((result, stylesheet) => {
      if(stylesheet.hasOwnProperty(key)) result = `${result} ${stylesheet[key]}`;
      return result;
    }, '').trim();
  }

  getClassName(search) {
    let className = '';
    if(typeof search === 'string') className = this.find(search);
    else className = search.reduce((result, key) => `${result} ${this.find(key)}`, '');
    return className.trim();
  }

  getDefaultStyle() {
    return this.stylesheets[0];
  }
}

function withStyles(component, defaultStylesheet){
  component.styles = new StyleManager([defaultStylesheet]);
  return component;
}
StyleManager.withStyles = withStyles;

export {
  withStyles,
}
