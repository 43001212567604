import React from 'react';
import { withStyles } from 'module-utils';

import styles from '../style/cards.module.sass';

const ContactCard = function(props) {
  const className = `${CardStyles.getClassName('main')} ${CardStyles.getClassName('contact')} ${props.className}`;

  return (
    <div className={className} style={props.style}>
      <div className={CardStyles.getClassName('contactImage')} style={{ backgroundImage: `url('${props.image}')` }} />
      <div className={CardStyles.getClassName('contactInfo')}>
        <h3 className='center'>{props.name}</h3>
        <p className='center'>{props.function}</p>
      </div>
    </div>
  );
};

ContactCard.defaultProps = {
  className: '',
  style: {}
};

const Cards = {
  ContactCard,
}

const CardsWithStyles = withStyles(Cards, styles);
const CardStyles = CardsWithStyles.styles;

export default CardsWithStyles;
export {
  ContactCard,
  CardStyles,
}
