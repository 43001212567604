import React from 'react';
import { Link } from 'gatsby';
import { withStyles } from 'module-utils';

import styles from '../style/buttons.module.sass';

const IconButton = function(props) {
  const className = `${ButtonStyles.getClassName('main')} ${ButtonStyles.getClassName('icon')} ${props.className} ${props.icon ? `icon-${props.icon}` : ''}`;

  if (props.linking)
    if (props.linking.external)
      return <a className={className} style={props.style} href={props.linking.path} target={props.linking.target || '_blank'} rel='noopener noreferrer' />;
    else return <Link className={className} style={props.style} to={props.linking.path} />;

  return <button className={className} style={props.style} onClick={props.onClick} />;
};

IconButton.defaultProps = {
  className: '',
  icon: null,
  style: {},
  onClick: null,
  linking: null,
};

const CtaButton = function(props) {
  const className = `${ButtonStyles.getClassName('main')} ${ButtonStyles.getClassName('cta')} ${props.className}`;

  if (props.linking)
    if (props.linking.external)
      return (
        <a className={className} style={props.style} href={props.linking.path} target={props.linking.target || '_blank'} rel='noopener noreferrer'>
          {props.children}
        </a>
      );
    else
      return (
        <Link className={className} style={props.style} to={props.linking.path}>
          {props.children}
        </Link>
      );

  return (
    <button className={className} style={props.style} onClick={props.onClick}>
      {props.children}
    </button>
  );
};

CtaButton.defaultProps = {
  className: '',
  style: {},
  onClick: null
};

const Buttons = {
  IconButton,
  CtaButton,
}

const ButtonsWithStyles = withStyles(Buttons, styles);
const ButtonStyles = ButtonsWithStyles.styles;

export default ButtonsWithStyles;
export {
  IconButton,
  CtaButton,
  ButtonStyles,
}
