const React = require("react");
const Link = require("gatsby").Link;
const BasicComponents = require("react-basic-components").default;
const ScrollManager = require("react-basic-components").ScrollManager.getInstance();
const ScrollEvent = require("react-basic-components").ScrollEvent;
const Logo = require("./src/assets/logo_light.svg");

require('styling');
require('./src/style/testing.sass');
require('./src/style/custom.sass');

const CustomButtonStyle = require("./src/style/buttons.module.sass");
BasicComponents.Buttons.styles.add(CustomButtonStyle);

class Header extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      isTransparent: true,
    }
  }

  componentDidMount(){
    ScrollManager.addEventListener(new ScrollEvent(
      this.detectScroll,
      'Change Header',
      300
    ));
  }

  componentWillUnmount(){
    ScrollManager.removeEventListener('Change Header');
  }

  render(){
    return (
      <BasicComponents.Layout.Header
        className={(this.state.isTransparent) ? 'transparent' : 'opaque'}
        style={{
          backgroundColor: (this.state.isTransparent) ? 'transparent' : 'rgba(14, 10, 31,0.75)',
          height: (this.state.isTransparent) ? '' : '60px',
        }}
      >
        <Link to='/' className='logo'>
          <Logo alt='Sanne van Almkerk' width='auto' />
        </Link>
        <nav>
          <Link to='/'>Portfolio</Link>
          <Link to='/projecten'>Projecten</Link>
          <Link to='/over-sanne'>Over mij</Link>
          <Link to='/contact'>Kom in contact</Link>
        </nav>
      </BasicComponents.Layout.Header>
    )
  }

  detectScroll = (e) => this.setState({ isTransparent: !e.active, });
}

exports.wrapPageElement = ({ element, props }) => {
  //use to get current path -> console.log(props);
  const header = <Header />

  const footer = (
    <BasicComponents.Layout.Footer>
      <div className='align-center'>
        <p>
          <BasicComponents.Buttons.IconButton icon='instagram' linking={{external: true, path: 'https://www.instagram.com/sannevan.a/'}} />
          <BasicComponents.Buttons.IconButton icon='facebook' linking={{external: true, path: 'https://www.facebook.com/Sannemuah/'}} />
          <BasicComponents.Buttons.IconButton icon='linkedin' linking={{external: true, path: 'https://www.instagram.com/sannevan.a/'}} />
        </p>
        <p>
          &emsp;&emsp;Sanne van Almkerk&emsp;|&emsp;MUA&emsp;|&emsp;Ik@sannevanalmkerk.nl
        </p>
        <p style={{ fontSize: '0.6rem', fontWeight: 300, }}>
          © 2019-2020 Sanne van Almkerk
        </p>
      </div>
    </BasicComponents.Layout.Footer>
  )

  return (
    <BasicComponents.Layout header={header} footer={footer}>
      {element}
    </BasicComponents.Layout>
  )
}
